@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
    width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
    background: #00000011;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #00000033;
    border-radius: 0.25rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #000000aa;
}

code {
    @apply bg-teal-600/20 dark:bg-teal-300/20;
}
